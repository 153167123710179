import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { UserContext } from '../../context';
import {
	Message,
	Placeholder,
	PlaceholderLine,
	PlaceholderParagraph,
	Segment,
} from 'semantic-ui-react';
import { sekolahMateri } from '../../fetcher';

const CardPemakaian = () => {
	const { token, tahun_ajaran_id } = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(false);
	const [pemakaian, setPemakaian] = useState({
		total_lembar_jawab: 0,
		total_materi: 0,
		total_murid_aktif: 0,
	});

	useEffect(() => {
		retrieveShowPenggunaan(token, tahun_ajaran_id);
	}, [token, tahun_ajaran_id]);

	const retrieveShowPenggunaan = async (token, tahun_ajaran_id) => {
		setIsLoading(true);
		const fetch = await sekolahMateri.showPenggunaan(token, tahun_ajaran_id);
		const fetch_result = _.chain(fetch)
			.get('result.list_bulan', [])
			.find({ tanggal_pretty: moment().format('MMMM YYYY') })
			.value();

		const total_lembar_jawab = _.get(fetch_result, 'total_lembar_jawab');
		const total_materi = _.get(fetch_result, 'total_materi');
		const total_murid_aktif = _.get(fetch_result, 'total_murid_aktif');

		setPemakaian({ total_lembar_jawab, total_materi, total_murid_aktif });
		setIsLoading(false);
	};

	const { total_lembar_jawab, total_materi, total_murid_aktif } = pemakaian;

	return (
		<div>
			{isLoading ? (
				<Segment
					raised
					style={{ width: '183px' }}>
					<Placeholder>
						<PlaceholderParagraph>
							<PlaceholderLine />
							<PlaceholderLine />
						</PlaceholderParagraph>
					</Placeholder>
				</Segment>
			) : (
				<Message
					color="teal"
					style={{ padding: '.8em' }}>
					<div>
						<p
							style={{
								textAlign: 'right',
								fontSize: '.8em',
							}}>
							Pemakaian {moment().format('MMMM YYYY')}
							<br />
							<b>
								<Link
									to={{
										pathname: `/pengaturan/identitas-sekolah?ljd_terpakai=${total_lembar_jawab}`,
										state: { status: 'footer' },
									}}
									target="_blank">
									{total_lembar_jawab} LJD | {total_materi} Materi |{' '}
									{total_murid_aktif} Murid Aktif
								</Link>
							</b>
						</p>
					</div>
				</Message>
			)}
		</div>
	);
};

export default CardPemakaian;
