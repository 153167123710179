import _ from 'lodash';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';

import Footer from '../../components/Footer/Footer';
import Media from 'react-media';
import 'moment/locale/id';
import { UserContext } from '../../context';
import CardPemakaian from '../../collections/CardPemakaian/CardPemakaian';

const propTypes = {};
const defaultProps = {};

const Layout = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	.section-content {
		flex: 1;
	}
`;

const ContainerSticky = styled.div`
	@media only screen and (max-width: 768px) {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 199;
	}
`;
const getVersion = (list_role = []) => {
	const list = list_role.map((o) => o.nama);
	let version = '';
	if (_.includes(list, 'sistem')) {
		version = 'sistem';
	}
	return version;
};

const BaseContentLayout = ({ children, listRoute = [] }) => {
	const session = useContext(UserContext);
	const { roles = [] } = session;
	const user_role = getVersion(roles || []);

	const HeaderMenu = _.get(children, 0, <div />);
	const Content = _.get(children, 1, <div />);

	return (
		<Layout>
			<ContainerSticky>
				<Switch>
					{listRoute.map((o) => {
						const noHeader = o.header === false;
						const Comp = noHeader ? <div /> : HeaderMenu;
						return (
							<Route
								key={`header-${_.snakeCase(o.path)}`}
								path={o.path}
								exact>
								{Comp}
							</Route>
						);
					})}
				</Switch>
			</ContainerSticky>

			<div className="section-content">{Content}</div>
			<Switch>
				{listRoute.map((o) => {
					const noHeader = o.header === false;
					const Comp = noHeader ? (
						<div />
					) : (
						<Media query={{ maxWidth: 768 }}>
							{(v) =>
								!v && (
									<div
										style={{
											display: 'flex',
											padding: '1em',
											justifyContent: 'space-between',
											paddingLeft: '2em',
											paddingRight: '2em',
										}}>
										<div>
											<Footer
												fontSize=".65em"
												widthImage="36"
												paddingContainer="0"
											/>
										</div>
										{user_role === 'sistem' && <CardPemakaian />}
									</div>
								)
							}
						</Media>
					);
					return (
						<Route
							key={`footer-${_.snakeCase(o.path)}`}
							path={o.path}
							exact>
							{Comp}
						</Route>
					);
				})}
			</Switch>
		</Layout>
	);
};

BaseContentLayout.propTypes = propTypes;
BaseContentLayout.defaultProps = defaultProps;

export default BaseContentLayout;
